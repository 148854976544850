<template>
  <div
    v-if="trustpilotScore != null"
    class="footer_trustpilot"
    style="width: 10rem"
  >
    <a
      href="https://www.trustpilot.com/review/www.g-portal.com"
      rel="noopener"
      target="_blank"
      aria-label="Trustpilot"
    >
      <nuxt-img
        src="/sc/images/trustpilot/logo.svg"
        alt="Trustpilot"
        width="130"
        height="32"
        loading="lazy"
      />
    </a>

    <common-trust-pilot-stars :score="trustpilotScore.trustScore" />
    <p class="footer_trustpilot-text mb-0 lh-1 w-100">
      <span class="fs-tiny">
        Trustscore <strong>{{ trustpilotScore.trustScore }}</strong>
      </span>
      |
      <span class="fs-tiny text-capitalize"> {{ t(trustPilotRating) }} </span>
      |
      <span class="fs-tiny">
        {{
          t('general/non_special_texts/reviews_count', {
            count: trustpilotScore.totalReviews,
          })
        }}
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import {
  GetFooterTrustpilotScoreDocument,
  type GetFooterTrustpilotScoreQuery,
  type GetFooterTrustpilotScoreQueryVariables,
  type TrustPilotScore,
} from '~/apollo/types/types';

export default defineComponent({
  async setup() {
    const { $sentry } = useNuxtApp();
    const { t } = useI18n();

    const { data: trustpilotScore } = await useAsyncData(
      'gp-footer-trustpilot',
      async () => {
        try {
          const { data: response } = await useLegacyApollo<
            GetFooterTrustpilotScoreQuery,
            GetFooterTrustpilotScoreQueryVariables
          >(GetFooterTrustpilotScoreDocument);

          return response.reviewScore;
        } catch (error) {
          $sentry.captureException(error);
        }

        return null as TrustPilotScore;
      },
    );

    const trustPilotRating = computed(() => {
      if (trustpilotScore.value == null) return '';

      if (trustpilotScore.value.trustScore >= 4.3) {
        return 'general/trustpilot/rating/excellent';
      }

      if (trustpilotScore.value.trustScore >= 3.8) {
        return 'general/trustpilot/rating/great';
      }

      // lets assume we do not get below 3.3 ;)
      return 'general/trustpilot/rating/average';
    });

    return {
      t,
      trustpilotScore,
      trustPilotRating,
    };
  },
});
</script>
